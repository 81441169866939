import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { switchMap, tap } from 'rxjs/operators';

import { Currency } from '@k2/common/entities-state/types';
import { Backend } from '@k2/common/backend/backend';
import { FormDialogService } from '@k2/common/k2-forms/form-dialog/form-dialog.service';
import { formSpecToFields } from '@k2/common/k2-forms/fields';
import { TransitBundle } from '@k2/staff/content/clients/client/assignment/hhg/costing/quotes-review/transit-bundles/transit-bundles.component';

export interface BidSummaryReport {
  k2_logo: string,
  client_name: string,
  client_logo_image: string,
  currency: Currency,
  assignee_full_name: string,
  k2_id: string,
  origin_city: string,
  origin_country_iso2: string,
  destination_city: string,
  destination_country_iso2: string,
  selected_quote_id: string,
  selectedVendorName?: string,
  selectedVendorTotal?: string,
  selectedVendorBundleTotals?: { bundle_name: string, total: string }[],
  client_approved: boolean,
  confirm_billing_information: { address: string, email: string, phone: string },
  assignment_lead_information: {
    email: string,
    full_name: string,
    job_title: string,
    logo: string,
    mobile: string,
    office_address: string
  },
  comparison_tables: {
    comparison: {
      transits: {
        transit_id: string,
        transit_label: string,
        quotes: Quote[]
      }[],
      totals?: Total[],
      specialist_services_total: any,
      offerTotals: {[key: string]: string},
      bundles?: {
        id: string,
        name: string,
        description: string,
        quotes: Total[]
      }[]
    },
    itemised_comparison: {
      transit_id: string,
      transit_label: string,
      quotes: Quote[],
      insurance: { type_label: string, amount: number | string }[],
      not_aggregated: {
        [key: number]: {
          id: number,
          name: string,
          label: string,
          quotes: { [quote_id: string]: { quote_id: string, amount: number | string } }
        }
      },
      has_aggregated: boolean
    }[],
    itemised_comparison_bundles: TransitBundle[]
  },
  specialist_services: {
    location_city: string,
    services: { service_name: string, num_days: number, description: string, quote: string }[]
  }[],
  transits_overview: {
    transit_type: string,
    transit_label: string,
    transit_method: string,
    origin: { city: string, region: string, iso2: string, flag: string },
    destination: { city: string, region: string, iso2: string, flag: string },
    measurement_total: string,
    container: string,
    transit_text: string,
    assignee_full_name: string,
    estimated_delivery: string,
    is_policy: boolean,
    eta: string,
    volume: number
  }[],
  policy_data: { policy: boolean, policy_text: string },
  survey_measurement_label: string,
  additional_information: string,
  vendors: {
    [key: string]: {
      quote_option_id: string,
      vendor: {
        id: string,
        name: string,
        currency: Currency
      }
    }},
}

interface Total extends Quote {
  transits: { transit_type: string, transit_label: string, transit_total: string }[];
}

interface Quote {
  quote_id: string,
  quote_label: string,
  transit_total?: string,
  bundle_total?: string,
  quote_total?: string,
  door_to_door_total?: string
}

@Component({
  selector: 'app-bid-summary-report',
  templateUrl: './bid-summary-report.component.html',
  styleUrls: ['./bid-summary-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(300 + 'ms ease-in')),
      transition('true => false', animate(300 + 'ms ease-out'))
    ]),
    trigger('fromBottom', [
      state('false', style({ opacity: 0, transform: 'translateY(100%)' })),
      state('true', style({ opacity: 1, transform: 'translateY(0)' })),
      transition('false => true', animate(800 + 'ms ease-in')),
      transition('true => false', animate(800 + 'ms ease-out'))
    ])
  ]
})
export class BidSummaryReportComponent {

  offerHash: string;
  data: BidSummaryReport = null;

  animateDiv: { [key: string]: boolean[] } = {
    ['trusted-partner']: [false, false],
    overview: [false, false],
    approval: [false, false],
    ['summary-relocating']: [false],
    ['partner-cost-comparison']: [false, false],
    ['further-costs']: [false, false],
    ['storage-costs']: [false, false],
    ['scope-items']: [false, false],
    ['assignment-lead']: [false],
    ['approve-costs-button']: [false]
  };
  navigations: { title: string, link: string }[] = [
    { title: 'Trusted partner of choice', link: 'trusted-partner' },
    { title: 'Invoicing details', link: 'approval' },
    { title: 'Overview', link: 'overview' },
    { title: 'Comparison & detail', link: 'comparison-summary' },
    { title: 'Move details', link: 'move-details' },
    { title: 'K2 insights', link: 'about-k2' }
  ];
  selectedNavigationItem: string = '';
  scrollToClicked: boolean = false;
  socialNetworks: { icon: string, link: string }[] = [
    { icon: 'twitter', link: 'https://twitter.com/k2corporatemob' },
    { icon: 'linkedin', link: 'https://www.linkedin.com/company/758316/admin/' },
    { icon: 'instagram', link: 'https://www.instagram.com/k2corporatemobility/' }
  ];
  openedTransitTextIndex: number = 0;
  showClientData: boolean = false;
  showGoToTopBtn: boolean = false;
  showMenu: boolean = false;
  aboutK2Data: {
    title: string,
    subTitle: string,
    boldText: string,
    texts: string[],
    linkText: string,
    link: string,
    img: string,
    imgAlt: string,
    imgActive?: boolean,
    icon?: string,
    style?: string
  }[] = [
    {
      title: 'Ecovadis partnership',
      subTitle: 'We have achieved the Silver Award in year one EcoVadis assessment.',
      boldText: 'After outlining a number of ambitious goals in this area, including achieving Net Zero Scope 1 & 2 emissions by the end of 2023 and working towards a 40% carbon footprint reduction through our supply chain by the end of 2026, K2 committed to delivering transparent and credible reporting, to help monitor progress.',
      texts: [
        'Such reporting is crucial not only for enabling the organisation to identify strengths and improvement areas, but also to be able to communicate sustainability and ESG developments to stakeholders. Having signed up to become UN Global Compact participants, K2 will be required to submit an annual communication of progress (COP) report, the first of which is due to be submitted in February 2023. As an additional step to improving transparency and sustainability practices, we opted to partner with a sustainability ratings company to make an ongoing assessment of our operational practices and track quantitative metrics.'
      ],
      linkText: 'Read more here',
      link: 'https://k2corporatemobility.com/en/insights/in-conversation-with-samiya-hirji-account-manager-at-ecovadis/',
      img: 'ecovadis.jpeg',
      imgAlt: 'Ecovadis',
      imgActive: true,
      icon: 'leaves'
    },
    {
      title: 'Great Place To Work<span class="big-font-size">®</span>',
      subTitle: 'Towards the end of last year, all K2 employees were asked to complete a survey compiled by Great Place To Work <span class="small-font-size">®</span>, the global authority on workplace culture.',
      boldText: 'The survey results are now in and we are delighted to announce that K2 has received the coveted Great Place To Work<span class="small-font-size">®</span> certification! For the certifying organisation, a great place to work is one where everyone, no matter...',
      texts: [
        'The company’s leaders are caring and foster an environment in which everyone has a purpose, can contribute new ideas and is able to access the resources that they need to thrive in their role.',
        '2022 was a very successful year for K2, from both a financial and a client satisfaction perspective. It is our culture, one that we believe to be unique and truly special, which made this success possible, and we wanted to find a way to prove and to celebrate this.'
      ],
      linkText: 'Read more here',
      link: 'https://k2corporatemobility.com/en/insights/k2-receives-great-place-to-work-certification/',
      img: 'great_place_to_work.png',
      imgAlt: 'Great place to work',
      imgActive: true
    },
    {
      title: 'Cyber Essentials Plus Certification',
      subTitle: 'In our modern world, the importance of having secure technology cannot be overstated.',
      boldText: 'With global businesses dependent on the internet for their operations, cyber security measures are imperative to ensure the protection of information of both a company’s and its clients’.  Thus, K2 are proud to have received a Cyber Essentials Plus Certification...',
      texts: [
        'January 2023 has seen K2 take part in a rigorous cyber security audit – Cyber Essentials Plus. Cyber Essentials Plus is a certification  that’s backed by the UK Government and the NSCS. It tests to see if an organisation’s IT is secure against a cyber-attack, forming a clear picture of how robust the security measures they have in place are. This audit spanned three weeks and tested our protection from a baseline of cyber threats, such as malware, user access control, firewalls, secure configuration and finding vulnerability in external facing servers. The audit shows that we are able to protect ourselves from up to 80% of cyber threats businesses face every day.'
      ],
      linkText: 'Read more here',
      link: 'https://k2corporatemobility.com/en/insights/k2-achieves-cyber-essentials-plus-certification/',
      img: 'cyber_essentials.jpeg',
      imgAlt: 'Cyber essentials plus certification',
      imgActive: true
    },
    {
      title: 'ISO 27001 Recertified',
      subTitle: 'February 2023 saw our UK office take part in a rigorous ISO 27001 audit.',
      boldText: 'Testing our information security, cybersecurity and privacy protection. After a week of reviews, assessments and interviews, it gives us great pleasure to announce that we are recertified with ISO 27001! First achieving this certification in 2016, ISO 27001 demonstrates...',
      texts: [
        'First achieving this certification in 2016, ISO 27001 demonstrates our commitment to keeping our client, partner, and employee data secure, accrediting our implementation and maintenance of key technical, organisational and security measures.'
      ],
      linkText: 'Read more here',
      link: 'https://k2corporatemobility.com/en/insights/k2-are-iso-27001-recertified/',
      img: 'recertification.jpeg',
      imgAlt: 'Recertification',
      imgActive: true,
      style: 'object-position: 20px 8px; transform: scale(1.4);'
    }
  ];
  approving: boolean = false;

  storageItems: { description: string, cost: string }[] = [];
  additionalItems: { description: string, cost: string }[] = [];

  quotedItemsByVendorMap: { [key: string]: any } = {};
  quotedItemsByBundleByVendorMap: { [key: string]: any } = {};

  selectedQuote: Quote = null;
  selectedQuotesByBundleMap: {
    [key: string]: Quote
  } = {};

  quoteIdVendorCollection: {quoteId: string, vendorName: string, total: string}[] = [];

  get currencySymbol(): string {
    return `${this.data.currency.iso3} `;
  }

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private backend: Backend,
              private dialog: FormDialogService) {
    const { offerHash, id } = this.activatedRoute.snapshot.params;

    this.offerHash = offerHash;
    this.backend.hhg.getBidSummaryReportForm(this.offerHash)
      .pipe(switchMap(fieldsSpec => this.dialog.openGeneralForm({
        title: 'Enter your email to proceed with Moving Cost Estimate',
        submitLabel: 'Submit',
        onCancel: () => this.router.navigate(['/']),
        fields: formSpecToFields(fieldsSpec),
        onSubmit: value => this.backend.hhg.getBidSummaryReportData(this.offerHash, value).toPromise()
      })))
      .subscribe({
        next: (res: BidSummaryReport) => {
          this.data = res;

          if (this.data.comparison_tables.comparison.totals) {
            this.data.comparison_tables.comparison.totals.forEach(quote => {
              if (quote.quote_id === this.data.selected_quote_id) {
                this.selectedQuote = quote;
              }
            });
          } else {
            this.data.comparison_tables.comparison.bundles.forEach(bundle => {
              bundle.quotes.forEach(quote => {
                if (quote.quote_id === this.data.selected_quote_id) {
                  this.selectedQuote = quote;
                }
              })
            });
          }

          this.data.comparison_tables.comparison.bundles.forEach(bundle => {
            for (const quoteKey in bundle.quotes) {
              if (bundle.quotes[quoteKey].quote_id === this.data.selected_quote_id) {
                this.selectedQuotesByBundleMap[bundle.id] = bundle.quotes[quoteKey];
              }
            }
          });

          if (this.data.comparison_tables.itemised_comparison.length) {
            this.data.comparison_tables.itemised_comparison.forEach(transit => {
              if (transit.has_aggregated) {
                for (const quoteKey in transit.quotes) {
                  if (!this.quotedItemsByVendorMap[transit.quotes[quoteKey].quote_id]) {
                    this.quotedItemsByVendorMap[transit.quotes[quoteKey].quote_id] = [];
                  }

                  this.quotedItemsByVendorMap[transit.quotes[quoteKey].quote_id].push({
                    name: `Door to door transit totals - ${transit.transit_label}`,
                    label: `Door to door transit totals - ${transit.transit_label}`,
                    amount: transit.quotes[quoteKey].door_to_door_total
                  });
                }
              }

              for (const itemKey in transit.not_aggregated) {
                for (const quoteId in transit.not_aggregated[itemKey].quotes) {
                  if (!this.quotedItemsByVendorMap[quoteId]) {
                    this.quotedItemsByVendorMap[quoteId] = [];
                  }

                  this.quotedItemsByVendorMap[quoteId].push({
                    name: transit.not_aggregated[itemKey].name,
                    label: transit.not_aggregated[itemKey].label,
                    amount: transit.not_aggregated[itemKey].quotes[quoteId].amount
                  });
                }
              }

              if (transit.insurance.length) {
                for (const quoteKey in transit.quotes) {
                  if (!this.quotedItemsByVendorMap[transit.quotes[quoteKey].quote_id]) {
                    this.quotedItemsByVendorMap[transit.quotes[quoteKey].quote_id] = [];
                  }

                  transit.insurance.forEach(insurance => {
                    this.quotedItemsByVendorMap[transit.quotes[quoteKey].quote_id].push({
                      name: insurance.type_label,
                      label: insurance.type_label,
                      amount: insurance.amount
                    });
                  });
                }
              }
            });
          }
          if (this.data.comparison_tables.itemised_comparison_bundles.length) {
            this.data.comparison_tables.itemised_comparison_bundles.forEach(bundle => {
              if (!this.quotedItemsByBundleByVendorMap[bundle.id]) {
                this.quotedItemsByBundleByVendorMap[bundle.id] = {};
              }

              for (const transit of bundle.transits) {
                if (transit.has_aggregated) {
                  for (const quoteKey in transit.quotes) {
                    if (!this.quotedItemsByBundleByVendorMap[bundle.id][transit.quotes[quoteKey].quote_id]) {
                      this.quotedItemsByBundleByVendorMap[bundle.id][transit.quotes[quoteKey].quote_id] = [];
                    }

                    this.quotedItemsByBundleByVendorMap[bundle.id][transit.quotes[quoteKey].quote_id].push({
                      name: `Door to door transit totals - ${transit.transit_label}`,
                      label: `Door to door transit totals - ${transit.transit_label}`,
                      amount: transit.quotes[quoteKey].door_to_door_total
                    });
                  }
                }

                for (const item of transit.not_aggregated) {
                  for (const quoteId in item.quotes) {
                    if (!this.quotedItemsByBundleByVendorMap[bundle.id][quoteId]) {
                      this.quotedItemsByBundleByVendorMap[bundle.id][quoteId] = [];
                    }

                    this.quotedItemsByBundleByVendorMap[bundle.id][quoteId].push({
                      name: item.name,
                      label: item.label,
                      amount: item.quotes[quoteId].amount
                    });
                  }
                }

                if (transit.insurance.length) {
                  for (const quoteKey in transit.quotes) {
                    if (!this.quotedItemsByBundleByVendorMap[bundle.id][transit.quotes[quoteKey].quote_id]) {
                      this.quotedItemsByBundleByVendorMap[bundle.id][transit.quotes[quoteKey].quote_id] = [];
                    }

                    transit.insurance.forEach(insurance => {
                      this.quotedItemsByBundleByVendorMap[bundle.id][transit.quotes[quoteKey].quote_id].push({
                        name: insurance.type_label,
                        label: insurance.type_label,
                        amount: insurance.amount
                      });
                    });
                  }
                }
              }
            });
          }

          if (this.data.comparison_tables.comparison.totals) {
            const selectedQuote = this.data.comparison_tables.comparison.totals
              .find(({ quote_id }) => quote_id == this.data.selected_quote_id);

            this.data.selectedVendorName = selectedQuote.quote_label;
            this.data.selectedVendorTotal = selectedQuote.quote_total;
            this.data.selectedVendorBundleTotals = (this.data.comparison_tables.comparison.bundles || [])
              .map(bundle => {
                let bundleTotal;
                for (const quoteKey in bundle.quotes) {
                  if (bundle.quotes[quoteKey].quote_id === this.data.selected_quote_id) {
                    bundleTotal = bundle.quotes[quoteKey].bundle_total;
                  }
                }

                return {
                  bundle_name: bundle.name,
                  total: bundleTotal
                };
              });
          } else {
            this.data.selectedVendorBundleTotals = (this.data.comparison_tables.comparison.bundles || [])
              .map((bundle, index) => {
                let selected;
                for (const quoteKey in bundle.quotes) {
                  if (bundle.quotes[quoteKey].quote_id === this.data.selected_quote_id) {
                    selected = bundle.quotes[quoteKey];
                  }
                }

                if (index === 0) {
                  this.data.selectedVendorName = selected.quote_label;
                }
                return { bundle_name: bundle.name, total: selected.bundle_total };
              });
          }
          if (this.data.vendors && this.data.comparison_tables.comparison.offerTotals) {
            for (const vendor in this.data.vendors) {
              this.quoteIdVendorCollection.push({
                quoteId: this.data.vendors[vendor].quote_option_id,
                vendorName: this.data.vendors[vendor].vendor.name,
                total: this.data.comparison_tables.comparison.offerTotals[this.data.vendors[vendor].quote_option_id]
              });
            }
            this.quoteIdVendorCollection.sort((a, b) =>
              parseFloat(a.total.replace(',', '')) - parseFloat(b.total.replace(',', ''))
            );
          }

          setTimeout(() => {
            this.showClientData = true;
            if (id) {
              for (let key of Object.keys(this.animateDiv)) {
                if (key === id) {
                  break;
                }
                for (let i = 0; i < this.animateDiv[key].length; i++) {
                  this.animateDiv[key][i] = true;
                }
              }
              this.scrollTo(id, false);
            }
          });
        }
      });
  }

  onMenuClick() {
    this.showMenu = !this.showMenu;
  }

  scrollTo(idElement?: string, changeUrl = true) {
    this.scrollToClicked = true;
    const { offerHash } = this.activatedRoute.snapshot.params;

    if (idElement) {
      this.selectedNavigationItem = idElement;
      const element = document.getElementById(idElement);

      if (element) {
        if (changeUrl) {
          this.location.replaceState(`bid-summary/${offerHash}/${idElement}`);
        }
        element?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
    } else {
      const element = document.getElementById('first-item');

      this.location.replaceState(`bid-summary/${offerHash}`);
      element?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  closeAndScroll(link: string) {
    this.showMenu = false;
    this.scrollTo(link);
  }

  onToggleClick(i: number) {
    if (this.openedTransitTextIndex === i) {
      this.openedTransitTextIndex = null;
    } else {
      this.openedTransitTextIndex = i;
    }
  }

  onScroll(event) {
    this.showGoToTopBtn = event.target.scrollTop > 300;

    if (!this.scrollToClicked) {
      for (let i = 0; i < this.navigations.length; i++) {
        const top = document.getElementById(this.navigations[i].link).getBoundingClientRect().top;
        const bottom = this.navigations[i + 1] ? document.getElementById(this.navigations[i + 1].link).getBoundingClientRect().top : document.getElementById(this.navigations[i].link).getBoundingClientRect().bottom;

        if ((bottom > 160 && bottom < window.innerHeight / 2 - 50) && this.navigations[i + 1] && this.navigations[i + 1].link !== this.selectedNavigationItem) {
          this.selectedNavigationItem = this.navigations[i + 1].link;
        } else if ((top > window.innerHeight * 2 / 3 + 50 && top < window.innerHeight) && this.navigations[i - 1] && this.navigations[i - 1].link !== this.selectedNavigationItem) {
          this.selectedNavigationItem = this.navigations[i - 1].link;
        } else if (((top > 160 && top < window.innerHeight / 2 - 50) || (bottom > window.innerHeight * 2 / 3 + 50 && bottom < window.innerHeight)) && !this.selectedNavigationItem) {
          this.selectedNavigationItem = this.navigations[i].link;
        } else if (!this.navigations[i - 1] && top > window.innerHeight * 2 / 3 + 50 && top < window.innerHeight) {
          this.selectedNavigationItem = null;
        } else if (!this.navigations[i + 1] && bottom > 160 && bottom < window.innerHeight / 2 - 50) {
          this.selectedNavigationItem = null;
        }
      }
    } else {
      for (let i = 0; i < this.navigations.length; i++) {
        const top = document.getElementById(this.navigations[i].link).getBoundingClientRect().top;

        if (top > 160 && top < window.innerHeight / 2 - 50 && this.navigations[i].link === this.selectedNavigationItem) {
          this.scrollToClicked = false;
        }
      }
    }

    for (let key of Object.keys(this.animateDiv)) {
      const div = document.getElementById(key)?.getBoundingClientRect();

      if (div && !this.animateDiv[key][0] && div.top < (window.innerHeight - 160) / 2 + 520) {
        this._setAnimation(key, 0);
      }
    }
  }

  private _setAnimation(key: string, itemIndex: number) {
    if (itemIndex < this.animateDiv[key].length) {
      setTimeout(() => {
        this.animateDiv[key][itemIndex] = true;
        this._setAnimation(key, itemIndex + 1);
      }, itemIndex ? 500 : 200);
    }
  }

  objectValues<K>(object: { [key: string | number]: K }): K[] {
    return Object.keys(object).map(key => object[key]);
  }

  onApproveClick() {
    if (!this.approving) {
      this.approving = true;
      const fields = this.backend.hhg.fetchApprovingMCEForm(this.offerHash)
        .pipe(tap(() => this.approving = false), formSpecToFields);
      this.dialog.openGeneralForm({
        title: '',
        submitLabel: 'Approve',
        fields,
        onSubmit: value => this.backend.hhg.approveBidSummaryBillingInfo(this.offerHash, value).toPromise()
      }).subscribe({
        next: (res: boolean) => {
          this.data.client_approved = res;
          this.approving = false;
        },
        error: () => this.approving = false
      });
    }
  }
}
